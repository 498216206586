/* eslint-disable no-console */
// Them
import React from 'react';
import ReactDOM from 'react-dom';

// Us
import '@css/index.css';
import { reactPathTS } from '@components/utilities/Paths';
import { store, history } from '@store/store';
import { logger } from '@components/utilities/logger';
import { getAsync } from '@components/data/rest';
import UserProfile from '@models/UserProfile';
import { broadcastLogin } from '@components/data/NetworkMonitor';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SystemInformationUI from './pages/SystemInformation';

(async () => {
  let loggedin = false;
  // *** IMPORTANT ***
  // We cannot use the store here because it is not initialized yet. See App.tsx.
  let userProfile: UserProfile | undefined;
  await getAsync('UserProfiles/CurrentUser',
    async (response) => {
      loggedin = true;
      userProfile = await response.obj<UserProfile>();
    },
    async (errorResult) => {
      // NOTE: we return a 400 Status Code and a 401 error code to prevent the browser's default login popup if Windows Authentication is used.
      loggedin = ![401, 404].includes(errorResult.httpStatusCode) && !(errorResult.httpStatusCode === 400 && errorResult.errorCode === -401);
      // Always return true because we don't want to display any error toaster popups.
      return true;
    });
  console.log('                                                                                                    \n'
            + '                                               +++++++                                              \n'
            + '                                     +++++++++++++++++++++++++++                                    \n'
            + '                                   ++                        +++++++                                \n'
            + '                                                                   +++++                            \n'
            + '                                                                       ++++                         \n'
            + '                                                                          +++                       \n'
            + '          +++++++++   +++++++       ++          +++++        +++++      ++  +++        +++          \n'
            + '          ++++++++++  +++++++++     ++        +++++++++    +++++++++    ++    +++     +++           \n'
            + '          +++    ++++ +++    +++    ++       +++     +++  +++           ++      +++++++             \n'
            + '          ++++++++++  +++++++++     ++       ++       ++  ++   ++++++   ++       +++++              \n'
            + '          +++    ++++ +++++++       ++       +++     +++  +++     +++   ++      +++++++             \n'
            + '          ++++++++++  +++           ++++++++  +++++++++    ++++++++++   ++    +++     +++           \n'
            + '          +++++++++   +++           ++++++++    +++++        +++++ ++   ++   +++       +++          \n'
            + '                                                                                                    \n');
  if (loggedin) {
    logger.info('User logged in. Proceed with app rendering...');
    broadcastLogin(userProfile);

    ReactDOM.render(
      <React.StrictMode>
        <App store={store} history={history} userProfile={userProfile} />
      </React.StrictMode>,
      document.getElementById('root'),
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  } else if (window.location.pathname === '/rs/sysinfo' && window.location.hostname === 'localhost') {
    logger.info('Trying to acces sysinfo from localhost, allowing access');
    ReactDOM.render(
      <React.StrictMode>
        <SystemInformationUI />
      </React.StrictMode>,
      document.getElementById('root'),
    );
  } else {
    logger.info('User is not logged in. Redirecting to login page.');
    window.location.replace(`${reactPathTS}../login.aspx`);
  }
})();
